import React from "react";
import logo from "./pics/my_face.png";


export const Home = () => {

    const logoStyle = {
        height: "40vmin",
        pointerEvents: "none",
    } as React.CSSProperties;

    const contentsStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "#E2DEB9",
        textAlign: "center",
    } as React.CSSProperties;

    return (
        <div className='home page' style={contentsStyle}>
            <h1>Hi! I'm Brandon!</h1>
            <img src={logo} style={logoStyle} className='spinning' alt='logo' />
            <p>
                Welcome to my website!
            </p>
            <a
                className='App-link'
                href='https://www.youtube.com/watch?v=mx86-rTclzA'
                target='_blank'
                rel='noopener noreferrer'
            >
                Click to find out more!
            </a>
        </div>
	);
}

export default Home;