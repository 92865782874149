import React from "react";
import logoShort from "./pics/logo-short.png";
import logoLong from "./pics/logo-long.png";
import { Link } from "react-router-dom";


export const Navbar = (props: {width: number}) => {

    const headerStyle = {
        height: "80px",
        backgroundColor: "#081e34",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

        color: "#E2DEB9",
    } as React.CSSProperties;

    const logoStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    } as React.CSSProperties;

    return (<header style={headerStyle}>
        <Link to='/' className="navbar-logo" style={logoStyle}>
            <img src={props.width < 400 ? logoShort : logoLong} style={{height: "80px"}} alt="face logo that redirects to home page"/>
        </Link>
        <Links/>
    </header>)
}

const Links = () => {
    const linksStyle = {
        margin: "20px",
        fontSize: "2em",
    }

    return(
        <nav style={linksStyle}>
            <span>
                <Link to='/aboutme' className='no-change-link'>About Me</Link>
            </span>{" "}
            |{" "}
            <span>
                <Link to='/games' className='no-change-link'>Games</Link>
            </span>
        </nav>
    )
}

export default Navbar;
