import React from "react";

export const Footer = () => {

    const footerStyle = {
		height: "60px",
		width: "100%",

		backgroundColor: "#E2A46A",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",


	} as React.CSSProperties;

    return (<div style={footerStyle}>
        <SocialButton title="youtube" link="https://www.youtube.com/@bwinsley" color="#bb0000"/>
        <SocialButton title="envelope" link="mailto:brandon.winsley@gmail.com" color="green"/>
        <SocialButton title="linkedin" link="https://www.linkedin.com/in/bwinsley/" color="#007bb5"/>
        <SocialButton title="instagram" link="https://www.instagram.com/brandon.wins/" color="#125688"/>
        <SocialButton title="github" link="https://github.com/bwinsley" color="#171515"/>
        <div style={{width: "5px"}}/>
    </div>)
}

const SocialButton = (props: {title: string, link: string, color: string}) => {
    const iconStyle = {
        padding: "10px",
        fontSize: "25px",
        width: "25px",
        margin: "2px",
        textAlign: "center",
        textDecoration: "none",
        borderRadius: "50%",
        color: "white",
    } as React.CSSProperties;

    return (
        <a href={props.link} className={"fa fa-" + props.title} target='_blank' rel="noreferrer" style={{...iconStyle, background: props.color}}>
            <span style={{display: "none"}}>{props.title}</span>
        </a>
    )
}

export default Footer;