import React from "react";
import { Link, Outlet } from "react-router-dom";

export const Games = () => {



    return(<div className="centerAllChildren">
        <h1>Games</h1>
        <button><Link to="tower">Tower</Link></button>
        <button><Link to="slido">Slido</Link></button>
        <Outlet />
    </div>)
}

export default Games;