import React from "react";
import { Link } from "react-router-dom";


export const PageNotFound = () => {
    const contentsStyle = {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "#E2DEB9",
        textAlign: "center",
    } as React.CSSProperties;

    return (
        <div style={contentsStyle}>
            <h1>ERROR 404</h1>
            <p>You done gone used a bad link</p>
            <Link
                to="/"
                className='App-link'
            >
                Return to home
            </Link>
        </div>
	);
}

export default PageNotFound;