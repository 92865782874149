import React from "react";

export const TowerGame = () => {
const [gameStarted, setGameStarted] = React.useState(false);
	const [gameState, setGameState] = React.useState<number[][]>([[], [], []]);
	const [click, setClick] = React.useState(-1);
	const [win, setWin] = React.useState(false);
	const [moves, setMoves] = React.useState(0);
    const [message, setMessage] = React.useState("Select the tower you want to take a block from");
    const [windowSize, setWindowSize] = React.useState({
		width: 0,
		height: 0,
	});

    React.useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const centerChildStyleRow = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		flexDirection: "row",
        color: "#E2DEB9"
	} as React.CSSProperties;
    
	const centerChildStyleCol = {
        margin: "10px",
		width: "calc(100% - 20px)",
        color: "#E2DEB9",
	} as React.CSSProperties;

	const startGame = (nBlocks: number) => {
		var tempState: number[][] = [[], [], []];

		for (var i = 0; i < nBlocks; i++) {
			tempState[0].push(i);
		}

		setGameState(tempState);
		setGameStarted(true);
	};


	const checkWin = (nMoves: number) => {
		const nBlocks = gameState.flat().length;
		if (gameState[2].length !== nBlocks) return;

		setWin(true);
        setMessage("Success in " + nMoves + "!")
	};

	const handleClick = (tower: number) => {
        if (win)
            return;

		console.log("User clicked on tower number " + tower);

        // if no source tower has been chosen yet and the tower is not empty
		if (click === -1 && gameState[tower].length !== 0) {
			setClick(tower);
            setMessage("Move block from tower " + tower + " to which tower?");
			return;
		} else if (click === -1 && gameState[tower].length === 0) {
            setMessage("Tower " + tower + " is empty! Pick another one");
			return;
        }

		// click is FROM
		// tower is TO

        // if not a valid move
		if (gameState[click][0] >= gameState[tower][0]) {
            setMessage("Cannot move block from tower " + click + " to tower " + tower + "! Pick a new tower to move from...");
            setClick(-1);
			return;
		}

        setMessage("Moved block from tower " + click + " to tower " + tower + "! Pick a new tower to move from!");
		var tempState = gameState;
		tempState[tower].unshift(tempState[click].shift()!);
		setGameState(tempState);
		setClick(-1);
		setMoves((moves) => moves + 1);
		checkWin(moves + 1);
	};

	const reset = () => {

		setGameStarted(false);
		setGameState([[], [], []]);
		setClick(-1);
		setWin(false);
		setMoves(0);
		setMessage("Select the tower you want to take a block from");
	};

    const startBoxStyle = {
        backgroundColor: "#123B45",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    } as React.CSSProperties;

	return (
		<div>
			{!gameStarted ?
                <div style={startBoxStyle}>
                    <h2>Tower</h2>
                    <p>How many blocks do you want to start with?</p>
                    <div style={centerChildStyleRow}>
                        <button onClick={() => startGame(3)}>3</button>
                        <button onClick={() => startGame(4)}>4</button>
                        <button onClick={() => startGame(5)}>5</button>
                    </div>
                </div> :
                <div className="centerAllChildren" style={centerChildStyleCol}>
                    <h2>Tower</h2>
                    <p>Moves made: {moves}</p>
                    <div style={{height: "50px"}}>
                        <p>{message}</p>
                    </div>
                    <div
                        className={windowSize.width > 1000 ? "" : "centerAllChildren"}
                        style={windowSize.width > 1000 ? centerChildStyleRow : centerChildStyleCol}>
                        <Tower
                            blocks={gameState[0]}
                            tower={0}
                            handleClick={handleClick}
                        />
                        <Tower
                            blocks={gameState[1]}
                            tower={1}
                            handleClick={handleClick}
                        />
                        <Tower
                            blocks={gameState[2]}
                            tower={2}
                            handleClick={handleClick}
                        />
                    </div>
                    {gameStarted ? <button onClick={() => reset()}>Reset</button> : <button disabled>Reset</button>}
                </div>
            }
		</div>
	);
};

const Tower = (props: {
	blocks: number[];
	tower: number;
	handleClick: (tower: number) => void;
}) => {
	const style = {
		display: "flex",
		flexDirection: "row",
		border: "2px dashed #999",
		margin: "5px",
	} as React.CSSProperties;
	const innerStyleLeft = {
		height: "150px",
		width: "150px",
		borderRight: "1px solid",
		borderBottom: "1px solid",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
	} as React.CSSProperties;
	const innerStyleRight = {
		height: "150px",
		width: "150px",
		borderLeft: "1px solid",
		borderBottom: "1px solid",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-end",
	} as React.CSSProperties;

	const getColor = (block: number) => {
		if (block === 0) return "red";
		else if (block === 1) return "blue";
		else if (block === 2) return "green";
		else if (block === 3) return "yellow";
		else if (block === 4) return "purple";
		else if (block === 5) return "pink";
		else return "white";
	};

	var blocksLeft = props.blocks.map((block) => (
		<div
			style={{
				height: "25px",
				width: `${(block + 1) * 30}px`,
				border: "solid",
				backgroundColor: getColor(block),
                borderRight: "none"
			}}
		/>
	));
	var blocksRight = props.blocks.map((block) => (
		<div
			style={{
				height: "25px",
				width: `${(block + 1) * 30}px`,
				border: "solid",
				backgroundColor: getColor(block),
                borderLeft: "none"
			}}
		/>
	));

	return (
		<div style={style} onClick={() => props.handleClick(props.tower)}>
			<div style={innerStyleLeft}>{blocksLeft}</div>
			<div style={innerStyleRight}>{blocksRight}</div>
		</div>
	);
};