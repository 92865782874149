import React from "react";
import face from "./pics/face2.png";
import { Navigate } from "react-router-dom";


export const FromNFC = () => {

    const [time, setTime] = React.useState(5);

    React.useEffect(() => {
        const interval = setInterval(() => setTime(time - 1), 1000);
        return () => {
          clearInterval(interval);
        };
    }, [time]);

    if (time <= 0) {
        return <Navigate to="/" replace={true}/>
    }

    const logoStyle = {
        height: "40vmin",
        pointerEvents: "none",
    } as React.CSSProperties;

    const contentsStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "#E2DEB9",
        textAlign: "center",
    } as React.CSSProperties;

    return (<div className="page" style={contentsStyle}>
        <h1>You just tapped my NFC card!</h1>
        <img src={face} style={logoStyle} className='spinning' alt='logo' />
        {time > 0 ?
            <p>
                I will redirect you to the main site in {time}
            </p> :
            <p>Redirecting you now!</p>
        }
    </div>)
}

export default FromNFC;