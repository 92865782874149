import React from "react";

import img1 from "./slido/1.png";
import img2 from "./slido/2.png";
import img3 from "./slido/3.png";
import img4 from "./slido/4.png";
import img5 from "./slido/5.png";
import img6 from "./slido/6.png";
import img7 from "./slido/7.png";
import img8 from "./slido/8.png";

const images = [img1, img2, img3, img4, img5, img6, img7, img8];

export const Slido = () => {
	const shuffleOrder = () => {
		var toShuffle = [1, 2, 3, 4, 5, 6, 7, 8, null];
		var shuffled: (number | null)[] = [];
		for (var i = 0; i < 9; i++) {
			var index = Math.floor(Math.random() * toShuffle.length);
			shuffled.push(toShuffle[index]);
			toShuffle.splice(index, 1);
		}
		return [
			[shuffled[0], shuffled[1], shuffled[2]],
			[shuffled[3], shuffled[4], shuffled[5]],
			[shuffled[6], shuffled[7], shuffled[8]],
		];
	};

	const [gameState, setGameState] = React.useState(shuffleOrder());

	const containerStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		flexDirection: "column",
	} as React.CSSProperties;

	const handleClick = (id: number, position: number) => {
        var state = gameState;
        const row = Math.floor(position / 3);
        const col = position % 3;

        if (row !== 0) {
            if (gameState[row - 1][col] === null) {
                state[row - 1][col] = id;
                state[row][col] = null;
            }
        }

        if (row !== 2) {
            if (gameState[row + 1][col] === null) {
                state[row + 1][col] = id;
                state[row][col] = null;
            }
        }

        if (col !== 0) {
            if (gameState[row][col - 1] === null) {
                state[row][col - 1] = id;
                state[row][col] = null;
            }
        }

        if (col !== 2) {
            if (gameState[row][col + 1] === null) {
                state[row][col + 1] = id;
                state[row][col] = null;
            } 
        }

        setGameState([...state]);

		if (
			state[0][0] === 1 &&
			state[0][1] === 2 &&
			state[0][2] === 3 &&
			state[1][0] === 4 &&
			state[1][1] === 5 &&
			state[1][2] === 6 &&
			state[2][0] === 7 &&
			state[2][1] === 8 &&
			state[2][2] === null
		) {
            // action a win
		}
	};

    const handleKey = (key: string) => {
        var row = 1;
        var col = 1;

        gameState.flat().forEach((id, index) => {
            if (id === null) {
                row = Math.floor(index / 3);
                col = index % 3;
            }
        })

        if (key === 'ArrowUp' && row !== 2) {
            handleClick(gameState[row + 1][col]!, (row + 1) * 3 + col);
        } else if (key === 'ArrowDown' && row !== 0) {
            handleClick(gameState[row - 1][col]!, (row - 1) * 3 + col);
        } else if (key === 'ArrowLeft' && col !== 2) {
            handleClick(gameState[row][col + 1]!, row * 3 + (col + 1));
        } else if (key === 'ArrowRight' && col !== 0) {
            handleClick(gameState[row][col - 1]!, row * 3 + (col - 1));
        }
    }

	const gameStyle = {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		gridTemplateRows: "1fr 1fr 1fr",
	};

    return (
		<div style={containerStyle} tabIndex={0} onKeyDown={event => handleKey(event.key)}>
            <h2>Slido</h2>
			<div style={gameStyle}>
				{gameState.flat().map((id, position) => (
					<Square
						id={id}
						handleClick={handleClick}
						key={id}
						position={position}
					/>
				))}
			</div>
		</div>
	);
};

const Square = (props: {
	id: number | null;
	handleClick: (id: number, position: number) => void;
	position: number;
}) => {
	const style = {
		height: "150px",
		width: "150px",
	};
	return (
		<div style={style}>
			{props.id && (
				<img
					src={images[props.id - 1]}
					onClick={() => props.handleClick(props.id!, props.position)}
                    alt={"Slido image " + props.position}
				/>
			)}
		</div>
	);
};