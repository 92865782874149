import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import Footer from "./Footer";
import PageNotFound from "./PageNotFound";
import FromNFC from "./FromNFC";
import AboutMe from "./AboutMe";
import Games from "./Games";
import { TowerGame } from "./games/Tower";
import { Slido } from "./games/Slido";


const App = () => {

	const [windowSize, setWindowSize] = React.useState({
		width: 0,
		height: 0,
	});

	React.useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const pageStyle = {
		margin: "0",
		display: "grid",
	};

	const bodyStyle = {
		minHeight: `${windowSize.height - 80 - 60}px`,
		backgroundColor: "#123B45",
	}

	return (
		<div style={pageStyle}>
			<BrowserRouter>
				<Navbar width={windowSize.width}/>
				<div style={bodyStyle}>
					<Routes>
						<Route path='' element={<Home/>} />
						<Route path='nfc' element={<FromNFC/>} />
						<Route path='aboutme' element={<AboutMe/>} />
						<Route path='games' element={<Games/>}>
							<Route path='tower' element={<TowerGame/>} />
							<Route path='slido' element={<Slido/>} />
						</Route>
						<Route path='404' element={<PageNotFound/>} />
						<Route path='*' element={<Navigate to="/404" replace={true}/>} />
					</Routes>
				</div>
				<Footer/>
			</BrowserRouter>
		</div>
	);
};

export default App;

/*
081E34
123B45
2C8976
E2DEB9
E2A46A
*/
